import { useState } from 'react'
import { verifyEmail } from 'src/data/newUserProfile'
import { segment } from 'src/lib/Segments'

import { useAppSelector } from '@redux/hooks'

import { ActionButton, ActionModal } from '..'

export default function SendEmailVerification({
  label = 'Send Verification Email',
}: {
  label?: string
}) {
  const [isSendingEmail, setIsSendingEmail] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const { token } = useAppSelector((state) => state.currentUser)
  return (
    <>
      <ActionButton
        label={label}
        onClick={async () => {
          setIsSendingEmail(true)
          segment.header_send_verification_email()
          try {
            await verifyEmail(token)
            setShowMessageModal(true)
          } catch (err) {
            setShowErrorModal(true)
          }
          setIsSendingEmail(false)
        }}
        size="xsmall"
        customStyle="py-0.5 !text-sm"
        isLoading={isSendingEmail}
      />
      {showErrorModal && (
        <ActionModal
          title="Error"
          message="Unable to send email. Please try again!"
          actions={[
            {
              label: 'Close',
              onClick: () => {
                segment.header_verification_email_close()
                setShowErrorModal(false)
              },
            },
          ]}
        />
      )}
      {showMessageModal && (
        <ActionModal
          title="Email Sent"
          message="Check your email and follow the link to verify your email address."
          actions={[
            {
              label: 'Close',
              onClick: () => {
                segment.header_verification_email_close()
                setShowMessageModal(false)
              },
            },
          ]}
        />
      )}
    </>
  )
}
