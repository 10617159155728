import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { getPageName } from 'src/common/Constants'
import { segment } from 'src/lib/Segments'

const AddressSearchBar = dynamic(() => import('@components/Search/AddressSearchBar'), {
  ssr: false,
})

type Props = {
  hideTitle?: boolean
}

function ReadReview({ hideTitle = false }: Props) {
  const router = useRouter()

  return (
    <div className="pt-6 text-center">
      {!hideTitle && (
        <div className="pb-4 text-xl font-semibold text-blue-500">
          Read & Anonymously Share Rental Experiences
        </div>
      )}
      <div onClick={() => segment?.header_address_search(getPageName(router.asPath.split('/')[1]))}>
        <AddressSearchBar placeholder="Search address..." customStyles="max-w-md mx-auto" />
      </div>
    </div>
  )
}

export default ReadReview
