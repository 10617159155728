import Image from 'next/image'
import { segment } from 'src/lib/Segments'

import appStoreBadge from '@public/badge_app_store.svg'
import androidStoreBadge from '@public/badge_google_play.svg'

export default function AppstoreDownloads() {
  return (
    <div className="my-3 flex flex-col items-center justify-center md:flex-row md:space-x-2">
      <div className="w-24">
        <a
          href="https://apps.apple.com/us/app/openigloo/id1521697583"
          id="nav_app_store_navr"
          onClick={segment.footer_app_store}
        >
          <Image alt="app store" src={appStoreBadge} unoptimized />
        </a>
      </div>
      <div className="w-24">
        <a
          href="https://play.google.com/store/apps/details?id=com.openigloo&amp;hl=en_US&amp;gl=US"
          id="nav_google_play_nav"
          onClick={segment.footer_google_playstore}
        >
          <Image alt="android store" src={androidStoreBadge} unoptimized />
        </a>
      </div>
    </div>
  )
}
