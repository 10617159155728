import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { AiOutlineSearch } from 'react-icons/ai'
import { segment } from 'src/lib/Segments'

import ReadReview from '@components/newLayout/readReview'
import RentApartment from '@components/newLayout/rentApartment'
import { useAppSelector } from '@redux/hooks'

export default function Search({ setShowTabs, showTabs }) {
  const router = useRouter()
  const [selectedTab, setSelectedTab] = useState(null)
  const { isLoggedIn, profile } = useAppSelector((state) => state.currentUser)

  useEffect(() => {
    if (router.pathname == '/listings') {
      setSelectedTab('apartments')
    }
  }, [router])

  useEffect(() => {
    if (router.pathname == '/listings' && selectedTab == null) {
      setShowTabs(true)
      setSelectedTab('apartments')
    }
  }, [selectedTab, router])

  useEffect(() => {
    if (router.pathname == '/building/[city]/[buildingId]/[addressSlug]') {
      setSelectedTab(null)
    }
  }, [router])

  useEffect(() => {
    if (selectedTab == null && router.pathname !== '/listings') {
      setShowTabs(false)
    }
  }, [selectedTab])

  const isEmailNotVerified = () => {
    return isLoggedIn && profile && !profile.isEmailVerified
  }

  return (
    <div className="w-full">
      {showTabs ? (
        <div
          className={`flex w-full flex-row items-center justify-center rounded-3xl bg-light-20 sm:w-4/5 sm:justify-start
        `}
        >
          <div
            onClick={() => {
              setSelectedTab('reviews')
              segment?.header_read_review()
            }}
            className={`cursor-pointer text-center text-sm text-mid-300 ${
              selectedTab == 'reviews' && ' !bg-primary font-semibold !text-light-0'
            } w-1/2 rounded-3xl py-3`}
          >
            Read Reviews
          </div>
          <div
            onClick={() => {
              setSelectedTab('apartments')
              segment?.header_explore_listings()
            }}
            className={`cursor-pointer text-center text-sm text-mid-300 ${
              selectedTab == 'apartments' && ' !bg-primary font-semibold !text-light-0'
            } w-1/2 rounded-3xl py-3`}
          >
            Rent Apartments
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            setShowTabs(true)
            setSelectedTab('reviews')
          }}
          className={`mb-2.5 flex w-full cursor-pointer items-center space-x-2 rounded-full border border-light-40 bg-white px-4 py-1.5 ${
            router.pathname == '/' && 'mb-0'
          } ${router.pathname == '/' && isMobile && 'w-fit'}`}
        >
          <AiOutlineSearch size={24} color="grey" />
          {(!isMobile || router.pathname !== '/') && (
            <div className="inline-flex w-full whitespace-nowrap text-sm text-mid-300 sm:pr-24">
              Search address...
            </div>
          )}
        </div>
      )}
      {selectedTab == 'reviews' && (
        <div
          className={`fixed top-[56px] bottom-0 left-0 z-[100] !m-0 h-screen w-full bg-black bg-opacity-80 text-black sm:top-[72px] ${
            isEmailNotVerified() && 'top-[108px]'
          }`}
          onClick={() => setSelectedTab(null)}
        >
          <div
            className="w-full rounded-b-2xl bg-white px-4 pb-4"
            onClick={(e) => e.stopPropagation()}
          >
            <ReadReview />
          </div>
        </div>
      )}
      {selectedTab == 'apartments' && router.pathname !== '/listings' && (
        <div
          className={`fixed top-[56px] bottom-0 left-0 z-[100] !m-0 h-screen w-full bg-black bg-opacity-80 text-black sm:top-[72px] ${
            isEmailNotVerified() && 'top-[108px]'
          }`}
          onClick={() => setSelectedTab(null)}
        >
          <div
            className="w-full rounded-b-2xl bg-white px-4 pb-4"
            onClick={(e) => e.stopPropagation()}
          >
            <RentApartment />
          </div>
        </div>
      )}
    </div>
  )
}
