type Props = {
  type: 'info' | 'warning' | 'error'
  customStyle?: string
  children: React.ReactElement
}
export default function AlertBanner({ type, customStyle = null, children }: Props) {
  const bgStyle = {
    info: 'bg-primarylight',
    warning: 'bg-warning',
    error: 'bg-error',
  }
  const textStyle = {
    info: 'text-white',
    warning: 'text-black',
    error: 'text-white',
  }
  return (
    <div
      className={`${
        customStyle ||
        'fixed top-0 left-0 z-50 flex h-10 w-full items-center justify-center text-sm font-semibold'
      } ${bgStyle[type]} ${textStyle[type]}`}
    >
      {children}
    </div>
  )
}
