import { isMobile } from 'react-device-detect'

import DesktopNav from './Desktop'
import MobileNav from './Mobile'

export interface NavProps {
  showMobileTitle?: boolean
  title?: string
  setShowTabs?: (show: boolean) => void
  showTabs?: boolean
}

export default function Nav({ showMobileTitle, title }: NavProps) {
  return isMobile ? <MobileNav showMobileTitle={showMobileTitle} title={title} /> : <DesktopNav />
}
