import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import useIntersectionObserver from 'src/hooks/useIntersectionObserver'
import { segment } from 'src/lib/Segments'

import Search from '@components/Search'
import AlertBanner from '@components/generic/AlertBanner'
import SendEmailVerification from '@components/generic/SendEmailVerification'
import { useAppSelector } from '@redux/hooks'

import {
  shouldMinimiseHeader,
  shouldShowAddressSearchBar,
  shouldShowFullSearchBars,
  shouldShowWriteReview,
  xlHeader,
} from '../utils'
import ProfileMenu from './ProfileMenu'

const AddressSearchBar = dynamic(() => import('@components/Search/AddressSearchBar'), {
  ssr: false,
})

const {
  publicRuntimeConfig: { STATIC_ROOT },
} = getConfig()

const openiglooLogo = `${STATIC_ROOT}/logo.png`

export default function DesktopNav() {
  const { isLoggedIn, profile, currentCity } = useAppSelector((state) => state.currentUser)
  const router = useRouter()
  const ref = useRef()
  const onScreen = useIntersectionObserver(ref, { threshold: 0.5, rootMargin: '300px' })
  const [showTabs, setShowTabs] = useState(false)
  const [showAlert, setShowAlert] = useState(router.pathname === '/' ? false : true)
  const [navStyle, setNavStyle] = useState({
    backgroundColor: router.pathname === '/' ? 'bg-light-10' : 'bg-white', // Initial transform for no scroll
    borderBottom: router.pathname === '/' ? 'border-none' : 'border-b border-light-40',
  })

  useEffect(() => {
    if (router.pathname === '/') {
      const handleScroll = () => {
        if (window.scrollY > 55) {
          setNavStyle({
            backgroundColor: 'bg-white',
            borderBottom: 'border-b border-light-40',
          })
          setShowAlert(true)
        } else {
          setNavStyle({
            backgroundColor: 'bg-light-10', // Reset position at the top
            borderBottom: 'border-none',
          })
          setShowAlert(false)
        }
      }

      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [router.pathname])

  return (
    <>
      <nav
        className={`sticky top-0 z-[51] mx-auto w-full transition-all duration-300 ${navStyle.backgroundColor} ${navStyle.borderBottom}`}
      >
        <div
          className={`container mx-auto my-auto max-w-screen-lg ${
            xlHeader(router.pathname) && 'max-w-screen-xl'
          } px-2 pt-2 text-white`}
        >
          <div className="relative flex w-full items-start justify-between space-y-2">
            <Link href="/" passHref>
              <a
                onClick={segment.header_logo}
                className="relative my-2 mr-4 flex h-12 w-28 items-center justify-center self-center hover:cursor-pointer"
              >
                <Image
                  src={openiglooLogo}
                  alt="Home"
                  layout="fill"
                  objectFit="contain"
                  unoptimized
                />
              </a>
            </Link>
            <div className="flex-1 mx-2 flex w-full">
              {!onScreen && shouldMinimiseHeader(router.pathname) ? (
                <>
                  <AddressSearchBar currentCity={currentCity} />
                </>
              ) : (
                <>
                  {shouldShowFullSearchBars(router.pathname, false) && showAlert && (
                    <Search setShowTabs={setShowTabs} showTabs={showTabs} />
                  )}
                  {shouldShowAddressSearchBar(router.pathname) && currentCity?.id && (
                    <AddressSearchBar currentCity={currentCity} />
                  )}
                </>
              )}
            </div>
            <div className="flex-1 flex flex-row flex-wrap items-center justify-end gap-4">
              {shouldShowWriteReview(router.pathname) ? (
                <a
                  onClick={segment.header_write_review}
                  href="/review"
                  className={`flex flex-shrink-0 items-center space-x-2 rounded-full border border-primary bg-blue-25 px-4 py-1.5 text-dark-900`}
                >
                  <span>Write a Review</span>
                </a>
              ) : null}
              <ProfileMenu />
            </div>
          </div>
        </div>
        {isLoggedIn && profile && !profile.isEmailVerified && showAlert && (
          <AlertBanner type="error" customStyle="w-full justify-center items-center">
            <div className="flex flex-row items-center justify-center md:space-x-2">
              <span>Your email has not been verified.</span>
              <SendEmailVerification />
            </div>
          </AlertBanner>
        )}
      </nav>
      <div ref={ref}></div>
    </>
  )
}
