import getConfig from 'next/config'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect } from 'react'
import useLogout from 'src/hooks/useLogout'
import { segment } from 'src/lib/Segments'

import Separator from '@components/Separator'
import OIIcon from '@components/icons'
import { setAuthRequired } from '@redux/actions'
import { toggleNavMenu } from '@redux/actions/activity'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'

import AppstoreDownloads from '../../Footer/AppstoreDownloads'
import SocialLinks from '../../Footer/SocialLinks'

const { publicRuntimeConfig } = getConfig()
const openiglooLogo = `${publicRuntimeConfig.STATIC_ROOT}/logo.png`

const NavMenu = () => {
  const { isLoggedIn, profile } = useAppSelector((state) => state.currentUser)

  const logout = useLogout()
  const dispatch = useThunkDispatch()

  useEffect(() => {
    document.body.style.overflowY = 'hidden'

    return () => {
      document.body.style.overflowY = null
    }
  }, [])

  return (
    <nav className="fixed inset-0 z-[101] flex min-h-screen overflow-y-scroll text-black">
      <div className="flex h-screen w-screen flex-col overflow-y-scroll bg-light-20 p-4 pb-20">
        <div className="flex-grow">
          <div
            className="my-2 flex w-full flex-col space-y-2"
            onClick={() => dispatch(toggleNavMenu())}
          >
            <div className="inline-flex w-full items-center justify-between">
              <div className="text-lg font-semibold">Account</div>
            </div>
            {isLoggedIn && profile ? (
              <>
                <Link href="/profile">
                  <p className="flex items-center space-x-2" onClick={segment.header_menu_profile}>
                    <OIIcon name="profile" size="md" />
                    <span>{profile.email}</span>
                  </p>
                </Link>
                <p
                  className="flex items-center space-x-2"
                  onClick={() => {
                    segment.header_menu_logout()
                    logout()
                  }}
                >
                  <OIIcon name="logout" size="md" />
                  <span>Logout</span>
                </p>
              </>
            ) : (
              <p
                className="flex items-center space-x-2"
                onClick={() => {
                  segment.header_menu_login()
                  dispatch(setAuthRequired(true))
                }}
              >
                <OIIcon name="profile" size="md" />
                <span className="text-sm">Login</span>
              </p>
            )}
            <Separator />
          </div>
          <div className="my-2 flex flex-col space-y-2" onClick={() => dispatch(toggleNavMenu())}>
            <div className="text-lg font-semibold">Support</div>
            <a
              onClick={segment.header_menu_contactus}
              className="text-sm"
              href="mailto:info@openigloo.com"
            >
              Contact Us
            </a>
            <a href="/faqs" className="text-sm" onClick={segment.header_menu_faq}>
              FAQs
            </a>
            <Separator />
          </div>
          <div className="my-2 flex flex-col space-y-2" onClick={() => dispatch(toggleNavMenu())}>
            <div className="text-lg font-semibold">Community</div>
            <a
              className="text-sm"
              href="/nyc/apartments-for-rent-nyc"
              onClick={segment.footer_verified_listings}
            >
              Verified Apartment Listings
            </a>
            <a
              className="text-sm"
              href="/nyc/furnished-listings"
              onClick={segment.footer_furnished_listings}
            >
              <h4>openigloo Furnished NYC Apartments</h4>
            </a>
            <a
              className="text-sm"
              href="/community-guidelines"
              onClick={segment.header_menu_community_guidelines}
            >
              Community Guildelines
            </a>
            <a className="text-sm" href="/blogs" onClick={segment.header_menu_blogs}>
              Our Blogs
            </a>
            <a className="text-sm" href="/buildings" onClick={segment.footer_neighborhoods}>
              NYC Buildings and Landlords
            </a>
            <a className="text-sm" href="/cities/boston" onClick={segment.footer_neighborhoods}>
              Boston Buildings
            </a>
            <a
              className="text-sm"
              href="/blogs/rent-reports"
              onClick={segment.footer_nyclease_renewals_report}
            >
              NYC Lease Renewals Reports
            </a>
            <Separator />
          </div>
          <div className="my-2 flex flex-col space-y-2" onClick={() => dispatch(toggleNavMenu())}>
            <div className="text-lg font-semibold">For Landlords</div>
            <a
              className="text-sm"
              href="https://manager.openigloo.com/"
              onClick={segment.footer_submit_listings}
            >
              Submit Your Apartment Listings
            </a>
            <a
              className="text-sm"
              href="https://manager.openigloo.com/listings-quality-policy"
              onClick={segment.footer_listings_quality_policy}
            >
              Listings Quality Policy
            </a>
            <a
              className="text-sm"
              href="https://manager.openigloo.com/"
              onClick={segment.footer_owners_managers}
            >
              For Owners & Managers
            </a>
            <Separator />
          </div>
        </div>
        <div
          className="flex w-full flex-row items-start justify-between place-self-end px-4"
          onClick={() => dispatch(toggleNavMenu())}
        >
          <div className="flex w-max flex-col items-center">
            <a href="/" className="relative w-1/2" onClick={segment.header_logo}>
              <div className="relative mb-2 flex h-20 w-full">
                <Image
                  src={openiglooLogo}
                  alt="Home"
                  className="z-0"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                  unoptimized
                />
              </div>
            </a>
            <SocialLinks />
            <p className="my-2 text-xs text-gray-500 sm:text-right">
              © {new Date().getFullYear()}, openigloo Inc. All Rights Reserved.
            </p>
            <p className="pl-3 text-xs text-gray-500">{`Version ${publicRuntimeConfig.APP_VERSION}`}</p>
          </div>
          <AppstoreDownloads />
        </div>
        <div className="flex w-1/3 flex-col items-center text-xs"></div>
      </div>
    </nav>
  )
}

export default NavMenu
