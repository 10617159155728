import { useDispatch } from 'react-redux'
import { segment } from 'src/lib/Segments'

import { toggleNavMenu } from '@redux/actions/activity'
import { useAppSelector } from '@redux/hooks'

export default function NavMenuToggle({ backgroundWhite = false }) {
  const { showMobileNavMenuItems } = useAppSelector((state) => state.userActivity)
  const { customMapHeader } = useAppSelector((state) => state.searchListing)
  const dispatch = useDispatch()
  return (
    <div
      className={`translate z-50 flex h-8 items-center justify-between py-2 ${
        customMapHeader && 'mt-3'
      }`}
    >
      <div
        className={`${
          showMobileNavMenuItems ? 'space-y-2' : 'space-y-1'
        } none hamburger w-8 items-center hover:cursor-pointer`}
        onClick={() => {
          showMobileNavMenuItems ? segment.header_menu_close() : segment.header_menu_open()
          dispatch(toggleNavMenu())
        }}
      >
        <span
          className={`block h-0.5 origin-ham transform rounded-md transition-all duration-300 ${
            showMobileNavMenuItems ? 'rotate-45' : 'rotate-0'
          } ${backgroundWhite ? 'bg-white' : 'bg-primary'}`}
        ></span>
        <span
          className={`block h-0.5 transform  rounded-md transition-all duration-500 ${
            showMobileNavMenuItems ? 'translate-x-full opacity-0' : 'translate-x-0 opacity-100'
          } ${backgroundWhite ? 'bg-white' : 'bg-primary'}`}
        ></span>
        <span
          className={`block h-0.5 origin-ham transform rounded-md transition-all duration-300 ${
            showMobileNavMenuItems ? '-rotate-45 transform' : 'rotate-0'
          } ${backgroundWhite ? 'bg-white' : 'bg-primary'}`}
        ></span>
      </div>
    </div>
  )
}

{
  /* <div className="translate flex h-8 items-center justify-between py-2">
          <div
            className={`${
              isSideNavOpen ? 'space-y-2' : 'space-y-1'
            } none hamburger w-8 items-center hover:cursor-pointer`}
            onClick={() => {
              if (isSideNavOpen) {
                setIsClosingNav(true)
              } else {
                setIsClosingNav(false)
              }
              setIsSideNavOpen(true)
            }}
          >
            <span
              className={`block h-0.5 origin-ham transform rounded-md transition-all duration-300 ${
                isHomePage || isLandingPage
                  ? 'bg-white'
                  : isSideNavOpen
                  ? 'bg-white'
                  : 'mx-0.5 bg-bluegray-300'
              } ${isSideNavOpen ? 'rotate-45' : 'rotate-0'}`}
            ></span>
            <span
              className={`block h-0.5 transform  rounded-md transition-all duration-500 ${
                isHomePage || isLandingPage
                  ? 'bg-white'
                  : isSideNavOpen
                  ? 'bg-white'
                  : 'mx-0.5 bg-bluegray-300'
              } ${isSideNavOpen ? 'translate-x-full opacity-0' : 'translate-x-0 opacity-100'}`}
            ></span>
            <span
              className={`block h-0.5 origin-ham transform rounded-md transition-all duration-300 ${
                isHomePage || isLandingPage
                  ? 'bg-white'
                  : isSideNavOpen
                  ? 'bg-white'
                  : 'mx-0.5 bg-bluegray-300'
              } ${isSideNavOpen ? '-rotate-45 transform' : 'rotate-0'}`}
            ></span>
          </div>
        </div> */
}
