import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import useIntersectionObserver from 'src/hooks/useIntersectionObserver'
import { segment } from 'src/lib/Segments'

import Search from '@components/Search'
import AlertBanner from '@components/generic/AlertBanner'
import SendEmailVerification from '@components/generic/SendEmailVerification'
import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack'
import { useAppSelector } from '@redux/hooks'
import { titleCase } from '@utility/Utilities'

import { NavProps } from '..'
import { isBuildingPage, shouldShowAddressSearchBar, shouldShowFullSearchBars } from '../utils'
import NavMenu from './NavMenu'
import NavMenuToggle from './NavMenuToggle'

const AddressSearchBar = dynamic(() => import('@components/Search/AddressSearchBar'), {
  ssr: false,
})

const {
  publicRuntimeConfig: { STATIC_ROOT },
} = getConfig()

const openiglooLogo = `${STATIC_ROOT}/logo.png`

export default function MobileNav({ showMobileTitle = false, title = '' }: NavProps) {
  const router = useRouter()
  const { newbuilding } = useAppSelector((state) => state.currentBuilding)
  const { isLoggedIn, profile, currentCity } = useAppSelector((state) => state.currentUser)
  const { customMapHeader } = useAppSelector((state) => state.searchListing)
  const [showTabs, setShowTabs] = useState(false)
  const [showNav, setShowNav] = useState(router.pathname === '/' ? false : true)
  const { showMobileNavMenuItems, alignMenuNavWithModalTitle } = useAppSelector(
    (state) => state.userActivity
  )
  const ref = useRef()
  const onScreen = useIntersectionObserver(ref, { threshold: 0 })

  const isEmailNotVerified = () => {
    return isLoggedIn && profile && !profile.isEmailVerified
  }

  useEffect(() => {
    if (router.pathname === '/') {
      const handleScroll = () => {
        if (window.scrollY > 55) {
          setShowNav(true)
        } else {
          setShowNav(false)
        }
      }

      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [router.pathname])

  // const getTopPosition = () => {
  //   return isEmailNotVerified() ? 'top-16' : 'top-0'
  // }

  const NavToggleComp = useCallback(() => {
    return (
      <div
        className={`fixed z-[102] ${
          shouldShowFullSearchBars(router.pathname, true)
            ? alignMenuNavWithModalTitle
              ? 'top-2 right-3 z-[49]'
              : customMapHeader
              ? 'right-6 top-2'
              : 'right-6 top-4'
            : 'top-3 right-3'
        }`}
      >
        <NavMenuToggle />
      </div>
    )
  }, [alignMenuNavWithModalTitle])

  return (
    <div
      className={`fixed top-0 z-[60] w-full  bg-white transition-all duration-300 ${
        showNav ? 'block' : 'hidden'
      }`}
    >
      {isEmailNotVerified() && (
        <AlertBanner type="error" customStyle="text-sm w-screen">
          <div className="flex w-full flex-col items-center justify-center md:space-x-2">
            <span className="mx-auto">Your email has not been verified.</span>
            <SendEmailVerification />
          </div>
        </AlertBanner>
      )}
      <div ref={ref}></div>
      {shouldShowFullSearchBars(router.pathname, true) ? (
        <div
          className={`${
            router.pathname == '/' && 'justify-between pb-1'
          } bg-white-25 flex w-full items-center px-4 pt-3 sm:h-20`}
        >
          {!showTabs && (
            <div>
              <Link href="/" passHref>
                <a
                  onClick={segment.header_logo}
                  className="relative my-2 mr-4 flex h-8 w-14 items-center justify-center self-center hover:cursor-pointer"
                >
                  <Image
                    src={openiglooLogo}
                    alt="Home"
                    layout="fill"
                    objectFit="contain"
                    unoptimized
                  />
                </a>
              </Link>
            </div>
          )}
          <div
            className={`${showTabs ? 'w-full' : 'flex w-full justify-end space-x-4'} ${
              router.pathname !== '/' && !showTabs && 'mr-12'
            }`}
          >
            <Search setShowTabs={setShowTabs} showTabs={showTabs} />
            {!showTabs && router.pathname == '/' && (
              <div className="flex flex-row flex-wrap items-center justify-end">
                <a
                  onClick={segment.header_write_review}
                  href="/review"
                  className={`flex flex-shrink-0 items-center space-x-2 rounded-full border border-primary bg-blue-25 px-4 py-1.5 text-dark-900`}
                >
                  <span>Write a Review</span>
                </a>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`${
            !onScreen ? 'fixed top-0 left-0' : ''
          } z-[47] mr-2 flex h-14 w-full items-center justify-between bg-white shadow-sm`}
        >
          <div className="inline-flex h-14 w-full items-center space-x-2 p-2">
            {![
              '/profile',
              '/nyc-rent-stablized-calculator',
              '/terms-of-use',
              '/privacy-policy',
              '/',
            ].includes(router.asPath) &&
              router.pathname !== '/application' && (
                <button
                  className="w-max rounded-full bg-white p-2 shadow-md hover:cursor-pointer"
                  title={'Go Back'}
                  onClick={() => {
                    if (router.pathname.startsWith('/profile')) {
                      router.push('/profile')
                    } else {
                      router.back()
                    }
                  }}
                >
                  <IoIosArrowBack size={16} />
                </button>
              )}
            {shouldShowAddressSearchBar(router.pathname) && currentCity?.id && (
              <div className="my-auto w-4/5">
                <AddressSearchBar
                  initValue={
                    isBuildingPage(router.pathname) && newbuilding
                      ? titleCase(newbuilding.primaryAddress.fullAddress)
                      : ''
                  }
                />
              </div>
            )}
            {!shouldShowAddressSearchBar(router.pathname) && showMobileTitle ? (
              <div className="flex w-4/5 justify-center text-xl text-dark-900">{title}</div>
            ) : null}
          </div>
        </div>
      )}
      {!showTabs && router.pathname !== '/listings' && router.pathname !== '/' && <NavToggleComp />}
      {showMobileNavMenuItems ? <NavMenu /> : null}
    </div>
  )
}
