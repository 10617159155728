import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import useLogout from 'src/hooks/useLogout'
import { segment } from 'src/lib/Segments'
import useOnClickOutside from 'use-onclickoutside'

import Separator from '@components/Separator'
import { FaBars } from '@react-icons/all-files/fa/FaBars'
import { IoMdContact } from '@react-icons/all-files/io/IoMdContact'
import { setAuthRequired } from '@redux/actions'
import { useAppSelector } from '@redux/hooks'

interface ProfileMenuProps {
  customStyle?: string
  customMenuStyle?: string
}

const ProfileMenu = ({ customStyle, customMenuStyle }: ProfileMenuProps) => {
  const { isLoggedIn, profile } = useAppSelector((state) => state.currentUser)

  const dispatch = useDispatch()
  const logout = useLogout()
  const router = useRouter()

  const [showProfileMenu, setShowProfileMenu] = useState(false)

  const ref = useRef(null)
  useOnClickOutside(ref, () => {
    segment.header_menu_close()
    setShowProfileMenu(false)
  })

  return (
    <div className={customStyle}>
      <div className="z-50 flex items-center justify-center">
        {isLoggedIn ? (
          <div
            className="flex h-10 w-10 items-center justify-center rounded-full border border-bluegray-300 bg-white uppercase text-bluegray-300 hover:cursor-pointer"
            onClick={() => {
              segment.header_menu_open()
              setShowProfileMenu(true)
            }}
          >
            {profile.email[0]}
          </div>
        ) : (
          !router.pathname?.includes('revona') && (
            <div
              className="relative mr-2 inline-flex items-center space-x-2 rounded-full bg-white p-1 px-2 hover:cursor-pointer"
              onClick={() => {
                segment.header_menu_open()
                setShowProfileMenu(true)
              }}
            >
              <FaBars color={'#070052'} size={15} />
              <IoMdContact color={'#070052'} size={25} />
            </div>
          )
        )}
      </div>
      {showProfileMenu && (
        <div
          className={`absolute top-12 right-0 z-[100] flex min-w-48 flex-col rounded-md bg-white py-2 text-left text-sm text-blue-300 shadow-md ${customMenuStyle}`}
          ref={ref}
        >
          <a href="/blogs">
            <button
              className="w-full p-2 text-left text-dark-900 hover:cursor-pointer hover:bg-blue-25 sm:hidden"
              onClick={() => {
                segment.header_menu_blogs()
                setShowProfileMenu(false)
              }}
            >
              Blog
            </button>
          </a>
          <Separator customStyle={'sm:hidden'} />
          {isLoggedIn ? (
            <div>
              {profile && profile.email && (
                <p className="whitespace-normal  p-2 text-gray-500">{profile.email}</p>
              )}
              {!router.pathname?.includes('revona') && (
                <Link href="/profile">
                  <div
                    className="w-full p-2 text-dark-900 hover:cursor-pointer hover:bg-blue-25"
                    onClick={() => {
                      segment.header_menu_profile()
                      setShowProfileMenu(false)
                    }}
                  >
                    Profile
                  </div>
                </Link>
              )}
            </div>
          ) : (
            <button
              className="w-full p-2 text-left text-dark-900 hover:cursor-pointer hover:bg-blue-25"
              onClick={() => {
                segment.header_menu_login()
                dispatch(setAuthRequired(true))
                setShowProfileMenu(false)
              }}
            >
              Login
            </button>
          )}
          <button
            className="w-full p-2 text-left text-dark-900 hover:cursor-pointer hover:bg-blue-25"
            onClick={() => {
              if (isLoggedIn == true) {
                segment.header_menu_logout()
                logout()
              } else {
                segment.header_menu_signup()
                dispatch(setAuthRequired(true))
              }
              setShowProfileMenu(false)
            }}
          >
            {isLoggedIn ? 'Logout' : 'Signup'}
          </button>
          {!router.pathname?.includes('revona') && (
            <>
              <Separator />
              <Link href="/faqs">
                <div
                  className="w-full p-2 text-dark-900 hover:cursor-pointer hover:bg-blue-25"
                  onClick={() => {
                    segment.header_menu_faq()
                    setShowProfileMenu(false)
                  }}
                >
                  FAQs
                </div>
              </Link>
              <Link href="/community-guidelines">
                <div
                  className="w-full p-2 text-dark-900 hover:cursor-pointer hover:bg-blue-25"
                  onClick={() => {
                    segment.header_menu_community_guidelines()
                    setShowProfileMenu(false)
                  }}
                >
                  Community Guidelines
                </div>
              </Link>
              <Link href="/blogs">
                <div
                  className="w-full p-2 text-dark-900 hover:cursor-pointer hover:bg-blue-25"
                  onClick={() => {
                    setShowProfileMenu(false)
                  }}
                >
                  Rent Tips & Resources
                </div>
              </Link>
              <Link href="mailto:info@openigloo.com">
                <div
                  className="w-full p-2 text-dark-900 hover:cursor-pointer hover:bg-blue-25"
                  onClick={() => {
                    segment.header_menu_contactus()
                    setShowProfileMenu(false)
                  }}
                >
                  Contact Us
                </div>
              </Link>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ProfileMenu
